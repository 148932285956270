/*
 |-----------------------------------------------------------------------------
 | components/atoms/Checkbox/Checkbox.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

import { get, map } from 'lodash';
// import { ray } from 'node-ray/web';

import Error from '@/atoms/Error';

import * as ICheckbox from './types';

const Checkbox: FC<ICheckbox.IProps> = ({
	hideLegend,
	isDisabled,
	isInvalid,
	isOptional,
	label,
	name,
	onBlur,
	onChange,
	options,
	utilities,
	variant,
}) => {
	// ray('Debug atom Checkbox:', {
	// 	hideLegend: hideLegend,
	// 	isDisabled: isDisabled,
	// 	isInvalid: isInvalid,
	// 	isOptional: isOptional,
	// 	label: label,
	// 	name: name,
	// 	options: options,
	// 	utilities: utilities,
	// 	variant: variant,
	// }).red();

	let classes;
	switch (variant) {
		case 'simple':
			classes = [
				`font-normal text-teal-500`,
				`bg-transparent border-teal-500`,
			];
			break;
		case 'solid':
			classes = [`font-bold text-white`, `bg-white border-white rounded`];
			break;
		case 'underline':
			classes = [
				`font-normal text-brand-1`,
				`
					accent-pink-500
					bg-transparent
					border-brand-1
					h-[20px]
					text-brand-1
					w-[20px]
					focus:ring-brand-4
				`,
			];
			break;
		default:
			classes = ['', ''];
	}

	return (
		<fieldset
			className={`
				block
				text-xl
				sm:text-lg
				${classes[0]}
				${utilities ? utilities : ''}
			`}
			data-testid="checkbox"
		>
			<legend className={hideLegend ? 'sr-only' : ''}>{label}</legend>
			{map(options, (option, index) => (
				<label
					key={index + get(option, 'id')}
					className={`block cursor-pointer mt-1`}
					htmlFor={get(option, 'id')}
				>
					<input
						className={`
							cursor-pointer
							disabled:opacity-25
							${classes[1]}
						`}
						defaultValue={get(option, 'value')}
						disabled={isDisabled}
						id={get(option, 'id')}
						name={name}
						onBlur={onBlur}
						onChange={onChange}
						required={!isOptional}
						type="checkbox"
					/>
					<span className={`ml-2 text-base sm:text-sm`}>
						{get(option, 'label')}
					</span>
				</label>
			))}
			{isInvalid && (
				<Error text={get(isInvalid, 'message')} utilities={`mt-1`} />
			)}
		</fieldset>
	);
};

export default Checkbox;
